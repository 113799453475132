<template>
	<div
		class="mapbuttons absolute w-1/6 z-10 flex flex-col md:flex-row justify-between"
	>
		<div
			class="button rounded-full cursor-pointer h-16 w-16 flex items-center justify-center text-md p-3 leading-none font-semibold text-center border-solid"
			@click="toggleMapType('MAP_SILVER')"
			:class="getButtonClass('MAP_SILVER')"
		>
			Map
		</div>
		<div
			class="button rounded-full cursor-pointer h-16 w-16 flex items-center justify-center text-md p-3 leading-none font-semibold text-center border-solid"
			@click="toggleMapType('MAP_SATELLITE')"
			:class="getButtonClass('MAP_SATELLITE')"
		>
			AERIAL
		</div>
		<div
			class="button rounded-full cursor-pointer h-16 w-16 flex items-center justify-center text-md p-3 leading-none font-semibold text-center border-solid"
			@click="toggleMapType('MAP_STREET_VIEW')"
			:class="getButtonClass('MAP_STREET_VIEW')"
		>
			STREET<br />VIEW
		</div>
	</div>
</template>

<script>
	import eventBus from "../utils/event-bus";

	export default {
		name: "MapButtons",
		data: function() {
			return {
				activeButton: "MAP_SILVER",
			};
		},
		methods: {
			toggleMapType(mapType) {
				this.activeButton = mapType;
				eventBus.$emit("MAP_TYPE_CHANGED", mapType);
			},
			getButtonClass(param) {
				return this.activeButton === param ? "selected" : " ";
			},
		},
		mounted() {
			eventBus.$on("POIS_LOADED", function() {
				// do whatever when all the pois are loaded...
			});
		},
	};
</script>
<style scoped>
	.mapbuttons .button {
		font-family: Rift-Regular;
		font-family: Rift-Regular;
		width: 65px;
		height: 65px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		color: #000;
		word-break: break-all;
		text-align: center;
		padding: 10px;
		font-weight: 600;
		line-height: 18px;
		border: 1px solid black;
		cursor: pointer;
	}
	.mapbuttons .button.selected {
		background: var(--primary);
		color: #fff;
		border: 1px solid var(--primary);
	}
	@media only screen and (max-width: 768px) {
		.mapbuttons .button {
			padding: 6px 0;
			border: 0;
			font-size: 15px;
			border-radius: 0;
			border-top-left-radius: 75px;
			border-bottom-left-radius: 75px;
			width: 100px;
			height: auto;
		}
	}
</style>
