<template>
	<div class="sidebar w-4/6 md:w-1/6 absolute top-5 z-10">
		<div
			id="selectAmenities"
			class="button bg-primary flex flex-row justify-between items-center w-12/12 text-white uppercase tracking-widest text-xs cursor-pointer rounded-r-full px-2 py-1 mt-4 absolute z-10 select-none"
			@click="isLocalAmenitiesList = !isLocalAmenitiesList"
		>
			<h4>SELECT LOCAL AMENITIES</h4>
			<i
				class="fas text-white md:invisible"
				:class="[
					isLocalAmenitiesList == true ? 'fa-minus-circle' : 'fa-plus-circle',
				]"
			></i>
		</div>

		<div
			class="list bg-white pt-3 pb-2 md:p-2 pl-4 pr-0 md:py-4 mt-1  rounded-r-lg select-none box-shadow overflow-y-scroll"
			v-if="isLocalAmenitiesList"
		>
			<p style="font-size:12px; color:grey; margin-left:35px">
				Hover to highlight, click to open maps view
			</p>
			<!-- Single Category [Featured Location] starts-->
			<dl
				v-for="poiCategory in poiCategories"
				v-bind:key="'C' + poiCategory.id"
				class="text-xs relative select-none overflow-y-scroll md:overflow-hidden"
			>
				<dt
					class="category-title flex items-center text-xs pb-2 cursor-pointer"
					@click="toggleCategory(poiCategory)"
				>
					<img :src="require('@/' + poiCategory.icon)" />
					<p class="text-primary ml-2 tracking-wider font-semibold">
						{{ poiCategory.name }}
					</p>
					<i
						class="fas ml-auto text-primary"
						:class="[
							poiCategory.isOpen == true ? 'fa-minus-circle' : 'fa-plus-circle',
						]"
					></i>
				</dt>

				<dd class="pi fl" v-show="poiCategory.isOpen">
					<ul class="pl-8">
						<li
							v-for="poi in poiCategory.pois"
							v-bind:key="'C' + poiCategory.id + 'P' + poi.id"
							class="text-sm mb-2 cursor-pointer"
							:class="[
								poi.isSelected ? 'text-primary font-semibold' : '',
								poi.isDisabled ? 'pointer-events-none text-gray-500' : '',
							]"
							@click="handlePOIMouseClick(poi)"
							@mouseover="handlePOIMouseOver(poi)"
						>
							{{ poi.index + ". " + poi.business }}
						</li>
					</ul>
				</dd>
			</dl>
			<!-- Single Category [Featured Location] Ends-->
		</div>
	</div>
</template>

<script>
	import eventBus from "../utils/event-bus";
	export default {
		name: "Sidebar",
		props: ["poiCategories"],
		data: function() {
			return {
				isLocalAmenitiesList: true,
			};
		},
		methods: {
			toggleCategory(category) {
				category.isOpen = !category.isOpen;
				eventBus.$emit("CATEGORY_TOGGLED", category.id);
			},

			handlePOIMouseOver(poi) {
				this.setPOIHighlighted(poi);
				eventBus.$emit(
					"SIDEBAR:POI_MOUSE_OVER",
					this.getPoisDatailsByPlaceId(poi.placeId)
				);
			},

			handlePOIMouseClick(poi) {

				if( ( window.innerWidth >= 800 ) ){
					eventBus.$emit("SIDEBAR:POI_MOUSE_CLICK", poi.index);
				}
 
				
			},

			setPOIHighlighted(poi) {
				this.poiCategories.forEach(function(category) {
					category.pois.forEach(function(poi) {
						poi.isSelected = false;
					});
				});
				poi.isSelected = true;
			},

			setPOIEnabled(placeIdIndex) {
				this.poiCategories.forEach(function(category) {
					category.pois.forEach(function(poi) {
						if (poi.index === placeIdIndex) poi.isDisabled = false;
					});
				});
			},

			getPoisDatailsByPlaceId(placeId) {
				let poiDetail = null;
				this.poiCategories.forEach(function(category) {
					category.pois.forEach(function(poi) {
						if (poi.placeId === placeId) {
							poiDetail = poi;
						}
					});
				});
				return poiDetail;
			},
		},
		computed: {},
		mounted() {
			let app = this;
			eventBus.$on("POIS_LOADED", function() {
				// Do whatever when all the pois loaded...
			});

			eventBus.$on("POI_LOADED", app.setPOIEnabled);

			eventBus.$on("MAP:POI_MOUSE_OVER", app.setPOIHighlighted);

			
			if ( ( window.innerWidth <= 800 ) ) {
				this.isLocalAmenitiesList = false;
			} else {
				this.isLocalAmenitiesList = true;
			}
			
		},
	};
</script>

<style scoped>
	#selectAmenities {
		font-family: Rift-Regular;
		font-size: 19px;
		top: -30px;
	}

	#selectAmenities i {
		font-size: 13px;
	}
	.category-title p {
		font-size: 14px;
		font-family: "Open Sans", sans-serif;
	}

	.list {
		padding-top: 17px;
		max-height: 95vh;
		border-right: 5px solid white;
		border-top: 5px solid white;
		border-bottom: 5px solid white;
	}
	@media only screen and (max-width: 767px) {
		.list {
			max-height: 55vh;
		}
		#selectAmenities {
			top: -27px;
		}
	}
</style>
