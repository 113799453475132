<template>
	<div
		class="poi-details w-full md:max-w-xs rounded overflow-hidden shadow-lg absolute z-10 w-full p-3 md:p-0 bg-primary md:bg-transparent"
		:class="{ hidden: isHidden }"
	>
		<div
			class="poiImage bg-cover bg-no-repeat bg-center"
			v-if="poiDetail.image != undefined"
			:style="{ backgroundImage: `url(${getLocalImage(poiDetail.image)})` }"
		>
			<img
				class="w-full invisible"
				v-if="poiDetail.image != undefined"
				:src="getLocalImage(poiDetail.image)"
			/>
		</div>

		<div
			class="h-20 px-3 py-3 flex justify-between items-center bg-white leading-relaxed"
		>
			<div>
				<p
					class="font-rift-regular font-normal md:text-xl text-md tracking-widest text-primary uppercase"
				>
					{{ poiDetail.business }}
				</p>
				<p class="font-normal text-xs tracking-wide">{{ poiDetail.address }}</p>
			</div>
			<button
				class="visit flex items-center md:hidden"
				@click="handleLearnMore(poiDetail.index)"
			>
				<span class="text-right text-xs leading-none mr-3 whitespace-no-wrap"
					>Learn More<br />Click Here</span
				>
				<img src="../assets/imgs/icons/Mouse_V2_Blue@2x.png" />
			</button>
		</div>
	</div>
</template>

<script>
	import eventBus from "../utils/event-bus";
	export default {
		name: "POIDetails",
		data: function() {
			return {
				isHidden: true,
				poiDetail: {},
			};
		},
		methods: {
			getLocalImage(img) {
				return img.indexOf("http") != -1 ? img : require("@/" + img);
			},
			handlePOIMouseOver(payload) {
				this.isHidden = false;
				this.poiDetail = payload;
			},
			handleLearnMore(index) {
				eventBus.$emit("LEARN_MORE", index);
			},
		},
		created() {
			eventBus.$on("MAP:POI_MOUSE_OVER", this.handlePOIMouseOver);
			eventBus.$on("SIDEBAR:POI_MOUSE_OVER", this.handlePOIMouseOver);
		},
	};
</script>
<style scoped>
	.poiImage {
		height: 250px;
		width: auto;
	}
	@media only screen and (max-width: 767px) {
		.poiImage {
			height: 170px;
			width: auto;
		}
	}
</style>
