const MAP_SILVER = [{
		elementType: "geometry",
		stylers: [{
			color: "#f5f5f5",
		}, ],
	},
	{
		elementType: "labels.icon",
		stylers: [{
			visibility: "off",
		}, ],
	},
	{
		elementType: "labels.text.fill",
		stylers: [{
			color: "#616161",
		}, ],
	},
	{
		elementType: "labels.text.stroke",
		stylers: [{
			color: "#f5f5f5",
		}, ],
	},
	{
		featureType: "administrative.land_parcel",
		elementType: "labels.text.fill",
		stylers: [{
			color: "#bdbdbd",
		}, ],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [{
			color: "#eeeeee",
		}, ],
	},
	{
		featureType: "poi",
		elementType: "labels.text.fill",
		stylers: [{
			color: "#757575",
		}, ],
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [{
			color: "#e5e5e5",
		}, ],
	},
	{
		featureType: "poi.park",
		elementType: "labels.text.fill",
		stylers: [{
			color: "#9e9e9e",
		}, ],
	},
	{
		featureType: "road",
		elementType: "geometry",
		stylers: [{
			color: "#ffffff",
		}, ],
	},
	{
		featureType: "road.arterial",
		elementType: "labels.text.fill",
		stylers: [{
			color: "#757575",
		}, ],
	},
	{
		featureType: "road.highway",
		elementType: "geometry",
		stylers: [{
			color: "#dadada",
		}, ],
	},
	{
		featureType: "road.highway",
		elementType: "labels.text.fill",
		stylers: [{
			color: "#616161",
		}, ],
	},
	{
		featureType: "road.local",
		elementType: "labels.text.fill",
		stylers: [{
			color: "#9e9e9e",
		}, ],
	},
	{
		featureType: "transit.line",
		elementType: "geometry",
		stylers: [{
			color: "#e5e5e5",
		}, ],
	},
	{
		featureType: "transit.station",
		elementType: "geometry",
		stylers: [{
			color: "#eeeeee",
		}, ],
	},
	{
		featureType: "water",
		elementType: "geometry",
		stylers: [{
			color: "#c9c9c9",
		}, ],
	},
	{
		featureType: "water",
		elementType: "labels.text.fill",
		stylers: [{
			color: "#9e9e9e",
		}, ],
	},
];

const poiCategories = [{
		id: 1,
		name: "Featured Locations",
		isOpen: true,
		icon: "assets/imgs/icons/featured_icon@2x.png",
		pois: [{
				id: 1,
				business: "Whole Foods",
				placeId: "ChIJOzlY-l7Lj4ARIQXu7CsRNmY",
				address: "777 The Alameda, San Jose, CA 95126",
				website: "http://wholefoodsmarket.com",
				streetViewUrl: "https://www.google.com/maps/@37.3319037,-121.9042892,3a,75y,336.76h,97t/data=!3m6!1e1!3m4!1sdOHJIT9e6mQI_TYEn1VjZw!2e0!7i16384!8i8192",
				image: "assets/imgs/pois/whole_foods.png",
				pano: {
					lat: 37.3319037,
					lng: -121.9042892,
					heading: 336.76,
					pitch: 7
				}
			},
			{
				id: 2,
				business: "Diridon Station CalTrain",
				placeId: "ChIJ7ZAJsljLj4ARvRhAMF85-9Q",
				address: "65 Cahill St., San Jose 95110",
				website: "http://www.caltrain.com/stations/sanjosediridonstation.html",
				streetViewUrl: "https://www.google.com/maps/@37.3299142,-121.9020653,3a,75y,283.49h,92.24t/data=!3m6!1e1!3m4!1sZA5opgXoalNp-jlKAICFSw!2e0!7i16384!8i8192",
				image: "http://www.caltrain.com/Assets/stations/san+jose+diridon/San+Jose+Diridon+Station.jpg",
				pano: {
					lat: 37.3299142,
					lng: -121.9020653,
					heading: 283.49,
					pitch: 2.24
				}
			},
			{
				id: 3,
				business: "SAP Center",
				placeId: "ChIJq56TmaDMj4ARJJCpWy9v-i0",
				address: "525 W Santa Clara St, San Jose, CA 95113",
				website: "http://www.sapcenter.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3320985,-121.9003921,3a,75y,316.91h,97.83t/data=!3m6!1e1!3m4!1sJSYI1zqNGvGLAPK6nGvA5A!2e0!7i16384!8i8192",
				image: "https://media-exp1.licdn.com/dms/image/C561BAQH1p7YgqFbM2w/company-background_10000/0?e=2159024400&v=beta&t=Y1rH1W4TzrHLa9wYkVFZJzGeLQtv0FkL1dz9uQqej-Y",
				pano: {
					lat: 37.3320985,
					lng: -121.9003921,
					heading: 316.91,
					pitch: 7.83
				}
			},
			{
				id: 4,
				business: "San Pedro Square Market",
				placeId: "ChIJw3TfQ6LMj4ARpeP1KcTyh50",
				address: "87 N San Pedro Street, San Jose, CA 95110",
				website: "http://www.sanpedrosquaremarket.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3369561,-121.8943728,3a,75y,183.34h,93.25t/data=!3m7!1e1!3m5!1softvxDgavgh2w5IxTJ22OQ!2e0!3e11!7i16384!8i8192",
				image: "assets/imgs/pois/san_pedro_square.png",
				pano: {
					lat: 37.3369561,
					lng: -121.8943728,
					heading: 183.34,
					pitch: 3.25
				}
			},
			{
				id: 5,
				business: "Lyft Bike Station",
				placeId: "ChIJEYhc_F7Lj4ARb0kiaettC9E",
				address: "North side of the Alameda, West of Bush St",
				website: "https://www.google.com/maps/place/Bay+Wheels/@37.3319365,-121.9070769,17z/data=!4m8!1m2!2m1!1slyft+bikes!3m4!1s0x808fcb5efc5c8811:0xd10b6deb6922496f!8m2!3d37.3319323!4d-121.9048882!5m1!1e3",
				streetViewUrl: "https://www.google.com/maps/@37.3318469,-121.9050282,3a,75y,358.16h,87.82t/data=!3m6!1e1!3m4!1sBTktdySLwpXgs4cO55nybw!2e0!7i16384!8i8192",
				image: "https://www.mercurynews.com/wp-content/uploads/2019/06/SJM-L-LYFT.jpg?w=618",
				pano: {
					lat: 37.3318469,
					lng: -121.9050282,
					heading: 358.16,
					pitch: -3.18
				}
			},
			{
				id: 6,
				business: "Trader Joe's",
				placeId: "ChIJn0i78WTLj4ARGOPrICaz1C8",
				address: "635 Coleman Ave, San Jose, CA 95110",
				website: "http://locations.traderjoes.com",
				streetViewUrl: "https://www.google.com/maps/@37.3408282,-121.9096025,3a,75y,359.31h,92.96t/data=!3m6!1e1!3m4!1sAF1QipNeOhNLoAyXm-eLh-R3z6qznqejP-4iA_wlRm0Y!2e10!7i11000!8i5500",
				image: "assets/imgs/pois/trader_joes.png",
				pano: {
					lat: 37.3408282,
					lng: -121.9096025,
					heading: 359.31,
					pitch: 2.96
				}
			},
			{
				id: 7,
				business: "Starbucks",
				placeId: "ChIJBQA3D6PMj4ARvEAsAwRZbaI",
				address: "145 W Santa Clara St, San Jose, CA 95113",
				website: "https://www.starbucks.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3351509,-121.8928123,3a,75y,348.73h,95.75t/data=!3m7!1e1!3m5!1sLp5osqqEyDIMk8Yy3ocsDw!2e0!3e11!7i16384!8i8192",
				image: "https://images.pexels.com/photos/597933/pexels-photo-597933.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
				pano: {
					lat: 37.3351509,
					lng: -121.8928123,
					heading: 348.73,
					pitch: 5.75
				}
			},
			{
				id: 8,
				business: "Growlers Taphouse",
				placeId: "ChIJQ_5D6bjLj4AR9uu6PV4p-NU",
				address: "787 The Alameda #10, San Jose, CA 95126",
				website: "https://growlerusa.com/craft-beer-pubs/san-jose/inspired-eats/",
				streetViewUrl: "https://www.google.com/maps/@37.33183,-121.9054837,3a,75y,11.5h,93.81t/data=!3m6!1e1!3m4!1sdntuAUoNQhXZaaYzUG9AOA!2e0!7i16384!8i8192",
				image: "https://www.mercurynews.com/wp-content/uploads/2019/10/2-2-1.jpg?w=868",
				pano: {
					lat: 37.33183,
					lng: -121.9054837,
					heading: 11.5,
					pitch: 3.81
				}
			},
			{
				id: 9,
				business: "SP2 Bar and Restaurant",
				placeId: "ChIJawrlN6LMj4AR-Bhapw3p8Qs",
				address: "72 N Almaden Ave, San Jose, CA 95110",
				website: "https://www.sp2sanjose.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3358822,-121.8947794,3a,90y,60.13h,88.07t/data=!3m6!1e1!3m4!1srha4CMch5TFZgFZzHqjtAQ!2e0!7i16384!8i8192",
				image: "https://images.squarespace-cdn.com/content/v1/5e7b1e05fe831b4a359042ea/1585154285666-9NVU7WYBR2KGHD0OUI14/ke17ZwdGBToddI8pDm48kBtt9xol7EFh62NWcuOrMqEUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2dlFRDLw8RJpId9INg3FSrOa0Uota-GseC6SIv31Ftc8nm4bjm9DAHF2kOsIZRJKXnA/homepic1.JPG",
				pano: {
					lat: 37.3358822,
					lng: -121.8947794,
					heading: 60.13,
					pitch: -1.93
				}
			},
			{
				id: 10,
				business: "Cost Plus World Market",
				placeId: "ChIJCe5s4GPLj4ARzzOTwuy1HMk",
				address: "555 Coleman Ave, San Jose, CA 95110",
				website: "http://stores.worldmarket.com",
				streetViewUrl: "https://www.google.com/maps/@37.3398532,-121.9059554,3a,60y,195.72h,90t/data=!3m6!1e1!3m4!1syNgC4WiKN8iFnyRzNldt6g!2e0!7i16384!8i8192",
				image: "assets/imgs/pois/cost_plus_world_market.png",
				pano: {
					lat: 37.3398532,
					lng: -121.9059554,
					heading: 195.72,
					pitch: 0
				}
			},
			{
				id: 11,
				business: "Target",
				placeId: "ChIJDRcDjmPLj4ARikFQ9nzGatY",
				address: "533 Coleman Ave, San Jose, CA 95110",
				website: "http://target.com",
				streetViewUrl: "https://www.google.com/maps/@37.3393312,-121.9054271,3a,75y,124.86h,99.93t/data=!3m6!1e1!3m4!1sVxBCuC6IA8dSv5n2T2KtDw!2e0!7i13312!8i6656",
				image: "https://images.idgesg.net/images/article/2019/11/target-logo3-100819680-large.jpg",
				pano: {
					lat: 37.3393312,
					lng: -121.9054271,
					heading: 124.86,
					pitch: 9.93
				}
			},
			{
				id: 12,
				business: "Arena Green West Park",
				placeId: "ChIJCQYW6KDMj4AR-Vk_jjIN2wU",
				address: "N Autumn St, San Jose, CA 95113",
				website: "https://www.grpg.org/river-park-gardens/arena-green/",
				streetViewUrl: "https://www.google.com/maps/@37.3321871,-121.8991936,3a,75y,344.63h,99.6t/data=!3m6!1e1!3m4!1sbT6TiThxB2IPTrj2VH_f0g!2e0!7i16384!8i8192",
				image: "https://www.grpg.org/wp-content/uploads/2012/07/ArenaGreen.jpg",
				pano: {
					lat: 37.3321871,
					lng: -121.8991936,
					heading: 344.63,
					pitch: 9.6
				}
			},
		],
	},
	{
		id: 2,
		name: "Nightlife",
		isOpen: false,
		icon: "assets/imgs/icons/nightlife_icon@2x.png",
		pois: [{
				id: 1,
				business: "Loft Bar & Bistro",
				placeId: "ChIJmXNOg7zMj4ARl3VZADKRkZ8",
				address: "90 S 2nd St, San Jose, CA 95113",
				website: "https://loftbarandbistro.com/",
				streetViewUrl: "https://www.google.com/maps/@37.335092,-121.8882846,3a,75y,19.83h,93.66t/data=!3m6!1e1!3m4!1sVwiYX-sTPSp7K6iw80ZJIA!2e0!7i16384!8i8192",
				image: "https://media-cdn.tripadvisor.com/media/photo-w/14/3b/db/87/rooftop-patio-restaurant.jpg",
				pano: {
					lat: 37.335092,
					lng: -121.8882846,
					heading: 19.83,
					pitch: 3.66
				}
			},
			{
				id: 2,
				business: "The Continental Bar Lounge & Patio",
				placeId: "ChIJaXwMdrrMj4ARDIHcfCFfFxs",
				address: "349 S 1st St, San Jose, CA 95113",
				website: "http://thecontinentalbar.com/updates/",
				streetViewUrl: "https://www.google.com/maps/@37.3307252,-121.8865593,3a,75y,232.05h,88.29t/data=!3m7!1e1!3m5!1sXg_wR8KaCGY8Jo43NszGRg!2e0!6s%2F%2Fgeo2.ggpht.com%2Fcbk%3Fpanoid%3DXg_wR8KaCGY8Jo43NszGRg%26output%3Dthumbnail%26cb_client%3Dmaps_sv.tactile.gps%26thumb%3D2%26w%3D203%26h%3D100%26yaw%3D88.38373%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192",
				image: "http://thecontinentalbar.com/wp-content/uploads/2016/01/cntl_sld_1200xxxxxxx_03.jpg",
				pano: {
					lat: 37.3307252,
					lng: -121.8865593,
					heading: 232.05,
					pitch: -1.71
				}
			},
			{
				id: 3,
				business: "Five Point Bar",
				placeId: "ChIJUxPcpaPMj4ARrgCAC5CQxdY",
				address: "169 W. Santa Clara Street, San Jose, CA. 95113",
				website: "https://www.fivepointssj.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3347709,-121.8934765,3a,75y,321.81h,97.41t/data=!3m6!1e1!3m4!1sXpicymZ0QkFF8m1T3InGog!2e0!7i16384!8i8192",
				image: "https://images.squarespace-cdn.com/content/v1/5d7168508ad44900015ad6fa/1567744312299-YLBMZ2B4DBJQQZHRR6YI/ke17ZwdGBToddI8pDm48kPLaQiNNbY4UvKirWe036hh7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1Ud16FzSTFx3DDOfu3ECKOrjjrkncmHRykMVl64q-8PxwDA5MuYbduysEDAM1kV879Q/IMG_0715.jpg?format=1500w",
				pano: {
					lat: 37.3347709,
					lng: -121.8934765,
					heading: 321.81,
					pitch: 7.41
				}
			},
			{
				id: 4,
				business: "55 South",
				placeId: "ChIJ-zOhsbzMj4AR-bC3P-1vrrs",
				address: "55 S. 1st Street, San Jose, CA. 95113",
				website: "https://the55south.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3352559,-121.8899661,3a,75y,200.17h,87.71t/data=!3m6!1e1!3m4!1sI865lsflODMcSDcC1QW6GQ!2e0!7i16384!8i8192",
				image: "https://scontent-sjc3-1.xx.fbcdn.net/v/t1.0-9/30967_116334531861220_967113091_n.png?_nc_cat=100&_nc_ohc=gWMiGzHe0GAAX-oP1K7&_nc_ht=scontent-sjc3-1.xx&oh=2a6ba446f53c2cb29bbe9db33f843505&oe=5F003530",
				pano: {
					lat: 37.3352559,
					lng: -121.8899661,
					heading: 200.17,
					pitch: -2.29
				}
			},
			{
				id: 5,
				business: "Coterie Winery",
				placeId: "ChIJGUis-T0zjoARE0YBqLA8sKY",
				address: "885 W Julian Street, San Jose, CA. 95126",
				website: "https://coteriewinery.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3336434,-121.9076155,3a,63.9y,5.31h,79.1t/data=!3m6!1e1!3m4!1sITPZqkXqxEW-1CBlk9eMNQ!2e0!7i13312!8i6656",
				image: "https://s3-media0.fl.yelpcdn.com/bphoto/F5YMGGHi-QRPOwdJcUqN2Q/o.jpg",
				pano: {
					lat: 37.3336434,
					lng: -121.9076155,
					heading: 5.31,
					pitch: -11.9
				}
			},
			{
				id: 6,
				business: "Divine Winery Tasting Room",
				placeId: "ChIJFY1VTaPMj4ARJ-a6zEVu1IY",
				address: "40 Post Street, San Jose, CA. 95113",
				website: "https://www.divinewineries.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3350241,-121.8905113,3a,75y,161.1h,93.25t/data=!3m6!1e1!3m4!1sa6Q6yFVstvPuIkJznXdQmw!2e0!7i16384!8i8192",
				image: "https://www.divinewineries.com/uploads/7/1/3/3/71332207/published/img-20180905-183519.jpg?1537129940",
				pano: {
					lat: 37.3350241,
					lng: -121.8905113,
					heading: 161.1,
					pitch: 3.25
				}
			},
			{
				id: 7,
				business: "San Jose Brew Bike",
				placeId: "ChIJIeBrOKbMj4AR3uMpNOTe63s",
				address: "132 Park Avenue, San Jose, CA. 95113",
				website: "https://www.sanjosebrewbike.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3320915,-121.8900445,3a,75y,248.1h,98.92t/data=!3m6!1e1!3m4!1spE3S0_IhDeMWdfagFodH7w!2e0!7i16384!8i8192",
				image: "https://www.sanjose.org/sites/default/files/2018-01/img_0015_0.jpg",
				pano: {
					lat: 37.3320915,
					lng: -121.8900445,
					heading: 248.1,
					pitch: 8.92
				}
			},
			{
				id: 8,
				business: "Camino Brewing",
				placeId: "ChIJo2N0TrTMj4ARNVXBPtP2eAM",
				address: "718 S 1st Street, San Jose, CA. 95113",
				website: "http://caminobrewing.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3253047,-121.8824713,3a,75y,53.45h,103.22t/data=!3m7!1e1!3m5!1sAF1QipPiAYZP4tta8H9Jf5pbZOWqPEtngmMmbp_Yx9Yw!2e10!3e11!7i11000!8i5500",
				image: "https://s3-media0.fl.yelpcdn.com/bphoto/V4FA61pctop-9GMFW5VHcA/o.jpg",
				pano: {
					lat: 37.3253047,
					lng: -121.8824713,
					heading: 53.45,
					pitch: 13.22
				}
			},
			{
				id: 9,
				business: "Clandestine Brewing",
				placeId: "ChIJVc-1YzUzjoARr_7y4A-m3lM",
				address: "980 S 1st Street, San Jose, CA. 95110",
				website: "https://www.clandestinebrewing.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3218008,-121.8784122,3a,75y,253.94h,103t/data=!3m7!1e1!3m5!1sN-3vJYCM9ziuf3-EiKKWfQ!2e0!6s%2F%2Fgeo3.ggpht.com%2Fcbk%3Fpanoid%3DN-3vJYCM9ziuf3-EiKKWfQ%26output%3Dthumbnail%26cb_client%3Dmaps_sv.tactile.gps%26thumb%3D2%26w%3D203%26h%3D100%26yaw%3D324.95435%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192",
				image: "https://s3.amazonaws.com/clandestinebrewing/static/web/images/clandestine_brewing_logo.png",
				pano: {
					lat: 37.3218008,
					lng: -121.8784122,
					heading: 253.94,
					pitch: 13
				}
			},
		],
	},
	{
		id: 3,
		name: "Dining",
		isOpen: false,
		icon: "assets/imgs/icons/dining_icon@2x.png",
		pois: [{
				id: 1,
				business: "Henry's Hi-Life",
				placeId: "ChIJkXmJt6HMj4AR_CU3a1r9eYg",
				address: "301 W St John St, San Jose, CA 95110",
				website: "http://henryshilife.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3352798,-121.8979108,3a,75y,322.32h,94.46t/data=!3m6!1e1!3m4!1sZ-M151A-rOZh0PbIXC5clg!2e0!7i16384!8i8192",
				image: "https://media-cdn.tripadvisor.com/media/photo-s/0c/13/53/df/20160619-205528-largejpg.jpg",
				pano: {
					lat: 37.3352798,
					lng: -121.8979108,
					heading: 322.32,
					pitch: 4.46
				}
			},
			{
				id: 2,
				business: "Elyse",
				placeId: "ChIJR8A7a7vMj4ARrU60bn83oWA",
				address: "151 S 2nd Street, San Jose, CA. 95113",
				website: "https://www.elyse-restaurant.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3337322,-121.8872644,3a,47.3y,255.56h,96.68t/data=!3m7!1e1!3m5!1svRD41iacIdDRfFLs0Y4WCQ!2e0!6s%2F%2Fgeo1.ggpht.com%2Fcbk%3Fpanoid%3DvRD41iacIdDRfFLs0Y4WCQ%26output%3Dthumbnail%26cb_client%3Dmaps_sv.tactile.gps%26thumb%3D2%26w%3D203%26h%3D100%26yaw%3D113.477005%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192",
				image: "https://s3-media0.fl.yelpcdn.com/bphoto/jYG8qsAIz3uSdGlS_YM2Xw/o.jpg",
				pano: {
					lat: 37.3337322,
					lng: -121.8872644,
					heading: 255.56,
					pitch: 6.68
				}
			},
			{
				id: 3,
				business: "Habana Cuba",
				placeId: "ChIJuYJQ4VrLj4AR2SC_HS-iUF8",
				address: "387 S 1st Street, San Jose, CA. 95113",
				website: "https://998cuba.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3303409,-121.8862733,3a,75y,244.62h,95.86t/data=!3m7!1e1!3m5!1soUpWIIdYV7GVswXshtbXHQ!2e0!6s%2F%2Fgeo1.ggpht.com%2Fcbk%3Fpanoid%3DoUpWIIdYV7GVswXshtbXHQ%26output%3Dthumbnail%26cb_client%3Dmaps_sv.tactile.gps%26thumb%3D2%26w%3D203%26h%3D100%26yaw%3D15.451319%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192",
				image: "https://img1.wsimg.com/isteam/ip/65084408-7f8b-4f4c-a7e2-56986d21555b/logo/aeabadba-ea24-43e4-b272-54c7f74ac183.jpg/:/rs=h:568/ll",
				pano: {
					lat: 37.3303409,
					lng: -121.8862733,
					heading: 244.62,
					pitch: 5.86
				}
			},
			{
				id: 4,
				business: "Orchestra Palm Court Restaurant ",
				placeId: "ChIJf2BKKrfMj4ARMNbG9LKvmvo",
				address: "27 E William Street, San Jose, CA. 95112",
				website: "http://orchestriapalmcourt.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3288571,-121.884332,3a,75y,330.39h,91.39t/data=!3m6!1e1!3m4!1s0YVUwMrgZ1ao2Jb0tVGfXw!2e0!7i16384!8i8192",
				image: "https://s3-media0.fl.yelpcdn.com/bphoto/NwFKcocWCRXV3lTwHtqR9A/o.jpg",
				pano: {
					lat: 37.3288571,
					lng: -121.884332,
					heading: 330.39,
					pitch: 1.39
				}
			},
			{
				id: 5,
				business: "Culinary Corner Bistro",
				placeId: "ChIJV19k227Nj4ARYW00qgCQAJs",
				address: "321 S 1st Street, San Jose, CA. 95113",
				website: "https://www.culinarycornerbistro.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3311488,-121.8868772,3a,75y,240.37h,90.83t/data=!3m6!1e1!3m4!1sl0ieEwQ6ydgGXhpshjnj-Q!2e0!7i16384!8i8192",
				image: "https://static.wixstatic.com/media/dee8dd_7b7cc03f5cd24a4d9555165071c90630~mv2.png/v1/fill/w_927,h_941,al_c,q_90/dee8dd_7b7cc03f5cd24a4d9555165071c90630~mv2.webp",
				pano: {
					lat: 37.3311488,
					lng: -121.8868772,
					heading: 240.37,
					pitch: 0.83
				}
			},
			{
				id: 6,
				business: "The Grill on the Alley",
				placeId: "ChIJWwfFI7vMj4ARbK8xbuWfuHg",
				address: "172 S Market Street, San Jose, CA. 95113",
				website: "https://www.thegrill.com/locations/the-grill-san-jose-california",
				streetViewUrl: "https://www.google.com/maps/@37.3324266,-121.8891134,3a,75y,351.39h,84.41t/data=!3m7!1e1!3m5!1sYJeL3VHlCL8lsb3vQwKxcA!2e0!3e11!7i13312!8i6656",
				image: "assets/imgs/pois/the_grill.png",
				pano: {
					lat: 37.3324266,
					lng: -121.8891134,
					heading: 351.39,
					pitch: -5.59
				}
			},
			{
				id: 7,
				business: "Mezcal",
				placeId: "ChIJ895uV7vMj4ARotK7CscA9To",
				address: "25 W San Fernando Street, San Jose, CA. 95113",
				website: "http://mezcalrestaurantsj.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3341076,-121.8897779,3a,75y,337.31h,94.99t/data=!3m6!1e1!3m4!1soFTJGVUXIbKcYQ27JZVy5w!2e0!7i16384!8i8192",
				image: "https://media.bizj.us/view/img/11276860/20190404svbjmezcal-restaurantsan-josecalifornia-189*1200xx6720-3780-0-350.jpg",
				pano: {
					lat: 37.3341076,
					lng: -121.8897779,
					heading: 337.31,
					pitch: 4.99
				}
			},
			{
				id: 8,
				business: "The Farmers Union",
				placeId: "ChIJYwCXCaPMj4ARBEPm6Z8ZvJc",
				address: "151 W Santa Clara Street, San Jose, CA. 95113",
				website: "http://thefarmersunion.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3349725,-121.8930547,3a,75y,327.91h,109.56t/data=!3m6!1e1!3m4!1s76ECdusSOxu606GaGKVBWg!2e0!7i16384!8i8192",
				image: "assets/imgs/pois/farmers_union.png",
				pano: {
					lat: 37.3349725,
					lng: -121.8930547,
					heading: 327.91,
					pitch: 19.56
				}
			},
			{
				id: 9,
				business: "Sushi Confidential",
				placeId: "ChIJb7ZsAqPMj4ARn6g-beXz2yM",
				address: "26 N San Pedro St, San Jose, CA 95113",
				website: "http://sushiconfidential.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3360594,-121.8925908,3a,75y,251.91h,94.75t/data=!3m7!1e1!3m5!1sTCVpE5r2eBLvQshd94NhgQ!2e0!3e11!7i13312!8i6656",
				image: "http://sushiconfidential.com/mt-content/uploads/2019/02/poke-taco.jpg",
				pano: {
					lat: 37.3360594,
					lng: -121.8925908,
					heading: 251.91,
					pitch: 4.75
				}
			},
			{
				id: 10,
				business: "Bluefin",
				placeId: "ChIJ0wyO1VjLj4AR1tIhVgBHMpw",
				address: "754 The Alameda #10, San Jose, CA 95126",
				website: "http://bluefinsanjose.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3317879,-121.9048739,3a,75y,189.07h,94.77t/data=!3m6!1e1!3m4!1sEyNscxePR8C0fFx4s_rM9g!2e0!7i16384!8i8192",
				image: "http://bluefinsanjose.com/wp-content/uploads/2014/12/Bluefin-Homepage-Tile-23.png",
				pano: {
					lat: 37.3317879,
					lng: -121.9048739,
					heading: 189.07,
					pitch: 4.77
				}
			},
			{
				id: 11,
				business: "Hannah Coffee & Sweets",
				placeId: "ChIJ0wyO1VjLj4ARObqaoRs3Ge4",
				address: "754 The Alameda #80, San Jose, CA 95126",
				website: "http://www.hannahbakedthis.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3317515,-121.9056499,3a,75y,147.98h,87.07t/data=!3m6!1e1!3m4!1sVW7xJmQKvGgEQY7eIXbcOA!2e0!7i16384!8i8192",
				image: "https://images.squarespace-cdn.com/content/v1/567a0b4da128e661e5c703ab/1544167410880-6QJVJQNWNF9UJUBVPF27/ke17ZwdGBToddI8pDm48kDlOtGc1TH1gV21KzckLP_tZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7Xj1nVWs2aaTtWBneO2WM-sGFPwdFUVSrx-G6IHYqitR8WrOqWkITqQBeb_DqrzNig/e441e1aa69878cab-Hannah_Logo_Large1.jpg?format=300w",
				pano: {
					lat: 37.3317515,
					lng: -121.9056499,
					heading: 147.98,
					pitch: -12.93
				}
			},
			{
				id: 12,
				business: "Café Rosalena",
				placeId: "ChIJRfnPbF3Lj4ARe4KCBHXdexk",
				address: "1077 The Alameda, San Jose, CA 95126",
				website: "https://www.caferosalenasanjose.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3315491,-121.9112351,3a,75y,351.88h,95.28t/data=!3m6!1e1!3m4!1sXrpZfpnJkCQTvivumKZ6WA!2e0!7i16384!8i8192",
				image: "https://s3-media0.fl.yelpcdn.com/bphoto/FK72w-w6QqSTzeZPNRo2SQ/o.jpg",
				pano: {
					lat: 37.3315491,
					lng: -121.9112351,
					heading: 351.88,
					pitch: 5.28
				}
			},
			{
				id: 13,
				business: "Olla Cocina",
				placeId: "ChIJySbjB6PMj4AR2P_zrg1ObjI",
				address: "17 N San Pedro St, San Jose, CA 95110",
				website: "https://www.ollacocina.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3355148,-121.8932141,3a,75y,224.05h,94.56t/data=!3m8!1e1!3m6!1sAF1QipPoVZJwTbThL041hsY2YsNK5V7szr83tsTbc0Qk!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPoVZJwTbThL041hsY2YsNK5V7szr83tsTbc0Qk%3Dw203-h100-k-no-pi3.6715844-ya87.05921-ro-7.83892-fo100!7i11000!8i5500",
				image: "https://images.squarespace-cdn.com/content/v1/56d88bc086db430590474cd1/1563822708404-CEOKUNDEB8YW48EF7VYB/ke17ZwdGBToddI8pDm48kNTwEyAUsC8nNUHSpPleYX4UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYy7Mythp_T-mtop-vrsUOmeInPi9iDjx9w8K4ZfjXt2diaV79F7Arb2gpAba2Rk0E7literQouc9SAUZunWGmIdZtJ3qR9G2BYeA0wOAaeYNg/slider2.jpg?format=1500w",
				pano: {
					lat: 37.3355148,
					lng: -121.8932141,
					heading: 224.05,
					pitch: 4.56
				}
			},
		],
	},
	{
		id: 4,
		name: "Shopping",
		isOpen: false,
		icon: "assets/imgs/icons/shopping_icon@2x.png",
		pois: [{
				id: 1,
				business: "Office Depot",
				placeId: "ChIJdYo5bmTLj4ARUijuHANZmMw",
				address: "615 Coleman Ave, San Jose, CA 95110",
				website: "https://www.officedepot.com/storelocator/ca/san-jose/office-depot-2358/?cm_mmc=GMB-_-2358-_-Website-_-NS",
				streetViewUrl: "https://www.google.com/maps/@37.3404362,-121.9081492,3a,75y,184.39h,102.46t/data=!3m6!1e1!3m4!1s6Mtbw02_MQ5uor9RYDfgoQ!2e0!7i16384!8i8192",
				image: "https://officedepot.scene7.com/is/image/officedepot/02358_storefront?$OD%2DEnlarge$",
				pano: {
					lat: 37.3404362,
					lng: -121.9081492,
					heading: 184.39,
					pitch: 12.46
				}
			},
			{
				id: 2,
				business: "CVS Pharmacy",
				placeId: "ChIJ6Y18e17Lj4ARP-kwpfZbUcw",
				address: "821 The Alameda, San Jose, CA 95126",
				website: "https://www.cvs.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3318161,-121.9066184,3a,75y,308.91h,96.73t/data=!3m7!1e1!3m5!1sX1-b_1W2HIPoydX8S50pZg!2e0!6s%2F%2Fgeo2.ggpht.com%2Fcbk%3Fpanoid%3DX1-b_1W2HIPoydX8S50pZg%26output%3Dthumbnail%26cb_client%3Dmaps_sv.tactile.gps%26thumb%3D2%26w%3D203%26h%3D100%26yaw%3D110.78052%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192",
				image: "https://www.boccardocorp.com/wp-content/uploads/2016/06/821_the_alameda_san_jose-image1.jpg",
				pano: {
					lat: 37.3318161,
					lng: -121.9066184,
					heading: 308.91,
					pitch: 6.73
				}
			},
		],
	},
	{
		id: 5,
		name: "Entertainment",
		isOpen: false,
		icon: "assets/imgs/icons/entertainment_icon@2x.png",
		pois: [{
				id: 1,
				business: "The Tech Interactive",
				placeId: "ChIJR8HI1brMj4AR2rds4TPFH6k",
				address: "201 S Market Street, San Jose, CA. 95113",
				website: "https://www.thetech.org/imax/presentations",
				streetViewUrl: "https://www.google.com/maps/@37.3319043,-121.8898781,3a,75y,218.79h,101.79t/data=!3m6!1e1!3m4!1spO9ZBoFZPUZiWYnOGepcTA!2e0!7i16384!8i8192",
				image: "https://www.thetech.org/sites/default/files/tti_1.jpg",
				pano: {
					lat: 37.3319043,
					lng: -121.8898781,
					heading: 218.79,
					pitch: 11.79
				}
			},
			{
				id: 2,
				business: "San Jose Museum of Art",
				placeId: "ChIJVx91TrvMj4ARzWqeIcRwuXs",
				address: "110 S Market Street, San Jose, CA. 95113",
				website: "https://sjmusart.org/",
				streetViewUrl: "https://www.google.com/maps/@37.3332071,-121.890077,3a,75y,352.82h,100.5t/data=!3m6!1e1!3m4!1s3IdaXWAOTX8wDIFE1ACiXQ!2e0!7i13312!8i6656",
				image: "https://www.visitcalifornia.com/sites/default/files/styles/welcome_image/public/VCW_D_Sjose_T7_SanJoseMuseumofArt_1280x642.jpg",
				pano: {
					lat: 37.3332071,
					lng: -121.890077,
					heading: 352.82,
					pitch: 10.5
				}
			},
			{
				id: 3,
				business: "Children's Discovery Museum",
				placeId: "ChIJHyD5sa_Mj4ARURwnBwmTwTU",
				address: "180 Woz Way, San Jose, CA. 95110",
				website: "https://www.cdm.org/",
				streetViewUrl: "https://www.google.com/maps/@37.3272331,-121.8917835,3a,75y,357.41h,97.67t/data=!3m6!1e1!3m4!1sAF1QipMD-R4eOilTAkvCZirdUgI_vYPO65ZQbV8FqYdX!2e10!7i5376!8i2688",
				image: "https://yerbabuenagardens.com/wp-content/uploads/2019/08/CCM_Plaza_1024x768.jpg",
				pano: {
					lat: 37.3272331,
					lng: -121.8917835,
					heading: 357.41,
					pitch: 7.67
				}
			},
			{
				id: 4,
				business: "Little Italy",
				placeId: "ChIJMQ2_0qHMj4ARJSyUx7UUH9M",
				address: "323 West Saint John Street, San Jose, CA. 95110",
				website: "http://www.littleitalysj.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3363272,-121.898658,3a,75y,169.52h,93.93t/data=!3m7!1e1!3m5!1shiFrbV5gJLHCmdEYInpf2g!2e0!6s%2F%2Fgeo2.ggpht.com%2Fcbk%3Fpanoid%3DhiFrbV5gJLHCmdEYInpf2g%26output%3Dthumbnail%26cb_client%3Dmaps_sv.tactile.gps%26thumb%3D2%26w%3D203%26h%3D100%26yaw%3D124.31257%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192",
				image: "https://www.sanjose.org/sites/default/files/little_italy_sign_1600.jpg",
				pano: {
					lat: 37.3363272,
					lng: -121.898658,
					heading: 169.52,
					pitch: 3.93
				}
			},
			{
				id: 5,
				business: "San Jose Civic",
				placeId: "ChIJScCHurrMj4AR3x6HUv5hclw",
				address: "135 W San Carlos Street, San Jose, CA. 95113",
				website: "https://sanjosetheaters.org/theaters/san-jose-civic/",
				streetViewUrl: "https://www.google.com/maps/@37.3304891,-121.889345,3a,75y,318.07h,99.18t/data=!3m6!1e1!3m4!1sF__6kogREFgxVkyMe8zBSw!2e0!7i16384!8i8192",
				image: "https://sanjosetheaters.org/wp-content/gallery/civic1a-cnc-exteriors/Tuite_SanJoseCivic-1-640.jpg",
				pano: {
					lat: 37.3304891,
					lng: -121.889345,
					heading: 318.07,
					pitch: 9.18
				}
			},
			{
				id: 6,
				business: "Center of the Performing Arts",
				placeId: "ChIJ_QhGaqXMj4AR5GHCXNt35E0",
				address: "255 S Almaden Blvd. San Jose, CA. 95113",
				website: "https://sanjosetheaters.org/theaters/center-for-performing-arts/",
				streetViewUrl: "https://www.google.com/maps/@37.329416,-121.8916447,3a,75y,303.42h,96.24t/data=!3m6!1e1!3m4!1s_cGJYp70Xgb2OlsXwr4SXw!2e0!7i16384!8i8192",
				image: "https://sanjosetheaters.org/wp-content/gallery/cpa1a-exteriors-1/CPA-Lite-copy.jpg",
				pano: {
					lat: 37.329416,
					lng: -121.8916447,
					heading: 303.42,
					pitch: 6.24
				}
			},
			{
				id: 7,
				business: "California Theater",
				placeId: "ChIJG6UbgLDMj4ARWPNSzqmoy0Q",
				address: "345 S1st Street, San Jose, CA. 95113",
				website: "https://sanjosetheaters.org/theaters/california-theatre/",
				streetViewUrl: "https://www.google.com/maps/@37.3307641,-121.8865879,3a,75y,243.88h,110.63t/data=!3m6!1e1!3m4!1sjd9Q38ctNRktNaLoxwAHhw!2e0!7i16384!8i8192",
				image: "https://sanjosetheaters.org/wp-content/gallery/ca2a-interiors/CDT_4798-640.jpg",
				pano: {
					lat: 37.3307641,
					lng: -121.8865879,
					heading: 243.88,
					pitch: 20.63
				}
			},
			{
				id: 8,
				business: "Montgomery Theater",
				placeId: "ChIJwTUa-q_Mj4ARwkrv0iR5YT8",
				address: "271 S Market Street, San Jose, CA. 95113",
				website: "https://sanjosetheaters.org/theaters/montgomery-theater/",
				streetViewUrl: "https://www.google.com/maps/@37.3312524,-121.8893081,3a,89.9y,197.43h,89.72t/data=!3m7!1e1!3m5!1spFAWgzYxbnfxLZYoe4_gtQ!2e0!3e11!7i13312!8i6656",
				image: "https://sanjosetheaters.org/wp-content/gallery/monty1a-exteriors/CDT_2173-640.jpg",
				pano: {
					lat: 37.3312524,
					lng: -121.8893081,
					heading: 197.43,
					pitch: -0.28
				}
			},
			{
				id: 9,
				business: "Tabard Theater Co",
				placeId: "ChIJMRNiqqPMj4ARRW-vHXJuV24",
				address: "29 N San Pedro Street, San Jose, CA. 95110",
				website: "https://www.tabardtheatre.org/",
				streetViewUrl: "https://www.google.com/maps/@37.3358131,-121.8934382,3a,75y,184.2h,100.33t/data=!3m7!1e1!3m5!1sF3PdzEMiDm_KM6Gj5hAtNA!2e0!3e11!7i16384!8i8192",
				image: "https://www.tabardtheatre.org/wordpress/wp-content/uploads/2018/06/Tabard-Theatre-Logo-horizontal.png",
				pano: {
					lat: 37.3358131,
					lng: -121.8934382,
					heading: 184.2,
					pitch: 10.33
				}
			},
			{
				id: 10,
				business: "San Jose Children's Musical Theater",
				placeId: "ChIJHa1MC1LLj4ARC_x4m5Wg0bM",
				address: "1401 Parkmoor Avenue, San Jose, CA. 95126",
				website: "https://www.cmtsj.org/",
				streetViewUrl: "https://www.google.com/maps/@37.3167136,-121.9124712,3a,75y,50.95h,84.02t/data=!3m6!1e1!3m4!1sW0KNsISZagUzwnohlCj1qw!2e0!7i16384!8i8192",
				image: "https://www.cmtsj.org/wp-content/uploads/2016/11/Im-Happy-500x500.jpg",
				pano: {
					lat: 37.3167136,
					lng: -121.9124712,
					heading: 50.95,
					pitch: -5.98
				}
			},
			{
				id: 11,
				business: "San Jose Institute of Contemporary Art",
				placeId: "ChIJ3U_ft7bMj4ARDcNsTlh79zo",
				address: "560 S 1st Street, San Jose, CA. 95113",
				website: "https://www.sjica.org/",
				streetViewUrl: "https://www.google.com/maps/@37.3276904,-121.8842143,3a,75y,38.58h,86.41t/data=!3m6!1e1!3m4!1sg60zWNIne7jvmGUaqatxDQ!2e0!7i16384!8i8192",
				image: "https://www.magnoliaeditions.com/wp-content/uploads/2012/05/SJICA-ImageF.00002-800x422.jpg",
				pano: {
					lat: 37.3276904,
					lng: -121.8842143,
					heading: 38.58,
					pitch: -3.59
				}
			},
		],
	},
	{
		id: 6,
		name: "Fitness",
		isOpen: false,
		icon: "assets/imgs/icons/fitness_icon@2x.png",
		pois: [{
				id: 1,
				business: "Orangetheory Fitness",
				placeId: "ChIJ6c51DGjLj4ARDJUrhWPYCJ8",
				address: "1360 The Alameda r18, San Jose, CA 95126",
				website: "https://www.orangetheoryfitness.com/",
				streetViewUrl: "https://www.google.com/maps/@37.333044,-121.9136864,3a,75y,48.41h,91.5t/data=!3m6!1e1!3m4!1sxC3LjKcE5xbgDkmBkb88wQ!2e0!7i16384!8i8192",
				image: "https://3989ac5bcbe1edfc864a-0a7f10f87519dba22d2dbc6233a731e5.ssl.cf2.rackcdn.com/otfcorpnew/feature-190531_optimized.jpg",
				pano: {
					lat: 37.333044,
					lng: -121.9136864,
					heading: 48.41,
					pitch: 1.5
				}
			},
			{
				id: 2,
				business: "Red Dot Fitness",
				placeId: "ChIJpQ_k8ak0joAREj3L5eWQYSQ",
				address: "850 The Alameda, San Jose, CA. 95126",
				website: "https://www.reddotfitness.net/",
				streetViewUrl: "https://www.google.com/maps/@37.3316979,-121.9068833,3a,75y,187.64h,90.39t/data=!3m7!1e1!3m5!1sIFrCCyVxlAV5BR4lmSi-lw!2e0!6s%2F%2Fgeo3.ggpht.com%2Fcbk%3Fpanoid%3DIFrCCyVxlAV5BR4lmSi-lw%26output%3Dthumbnail%26cb_client%3Dmaps_sv.tactile.gps%26thumb%3D2%26w%3D203%26h%3D100%26yaw%3D206.952%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192",
				image: "assets/imgs/pois/red_dot_fitness.png",
				pano: {
					lat: 37.3316979,
					lng: -121.9068833,
					heading: 187.64,
					pitch: 0.39
				}
			},
			{
				id: 3,
				business: "Westca Gym",
				placeId: "ChIJL2tMGr3Mj4ARF8jxUFQ11XM",
				address: "30 N 3rd St, San Jose, CA 95112",
				website: "https://www.westcasj.com/",
				streetViewUrl: "https://www.google.com/maps/@37.3376149,-121.8888158,3a,75y,60.5h,91.2t/data=!3m8!1e1!3m6!1sAF1QipMpRV9KHY40JFkvP_o1VcgYyzW3O1N7kt5kAtAO!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMpRV9KHY40JFkvP_o1VcgYyzW3O1N7kt5kAtAO%3Dw203-h100-k-no-pi0.5247976-ya92.77314-ro-4.244908-fo100!7i11000!8i5500",
				image: "https://images.squarespace-cdn.com/content/v1/59de8ac6a803bb017c4ff78d/1560455418486-AZHC4W689WSWKJR6EN28/ke17ZwdGBToddI8pDm48kMNb0sFoRTsm0e5jJ-WN1GN7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1Uadrn7yOVQ0sfhWgjdPpFMYBBca0tCfNTYHE0f9-4S72YpzMQ0K1wByJp2SwCVl1og/westcashop-1+%281%29.jpg?format=2500w",
				pano: {
					lat: 37.3376149,
					lng: -121.8888158,
					heading: 60.5,
					pitch: 1.2
				}
			},
			{
				id: 4,
				business: "CorePower Yoga",
				placeId: "ChIJN0H8l83Lj4ARSgN9unTEu5Q",
				address: "787 The Alameda Suite 20, San Jose, CA 95126",
				website: "http://corepoweryoga.com",
				streetViewUrl: "https://www.google.com/maps/@37.3318197,-121.9057086,3a,75y,359.09h,98.42t/data=!3m6!1e1!3m4!1sauwDvuyAN6FMK7eNz4tC8Q!2e0!7i16384!8i8192",
				image: "https://lh5.googleusercontent.com/p/AF1QipOQMDYfKWHWiM-xOgQfbfkFX3-8h-ybSyNFWVNT=w408-h408-k-no",
				pano: {
					lat: 37.3318197,
					lng: -121.9057086,
					heading: 359.09,
					pitch: 8.42
				}
			},
			{
				id: 5,
				business: "Be The Change Yoga & Wellness",
				placeId: "ChIJyXAZsbzMj4ARbnPpj181toQ",
				address: "947 Park Ave, San Jose, CA 95126",
				website: "https://btcwellness.org/",
				streetViewUrl: "https://www.google.com/maps/@37.3262108,-121.907764,3a,75y,347.73h,99.16t/data=!3m6!1e1!3m4!1sA3m5DBAF6hznK4gfHRgyPg!2e0!7i16384!8i8192",
				image: "assets/imgs/pois/be_the_change_yoga.png",
				pano: {
					lat: 37.3262108,
					lng: -121.907764,
					heading: 347.73,
					pitch: 9.16
				}
			},
		],
	},
];

export default {
	MAP_SILVER: MAP_SILVER,
	poiCategories: poiCategories,
};