<template>
	<transition
		:duration="5000"
		enter-active-class="animated slideInDown"
		leave-active-class="animated slideOutUp"
	>
		<div
			class="mapbuttons absolute w-1/6 z-10 flex flex-col md:flex-row justify-between"
			v-show="isVisible"
		>
			<div
				class="button rounded-none md:rounded-full cursor-pointer md:h-16 md:w-16 flex items-center justify-center text-md p-3 leading-none font-semibold text-center border-solid"
				@click="toggleMapType('MAP_SILVER')"
				:class="getButtonClass('MAP_SILVER')"
			>
				MAP
			</div>
			<div
				class="button rounded-none md:rounded-full cursor-pointer md:h-16 md:w-16 flex items-center justify-center text-md p-3 leading-none font-semibold text-center border-solid"
				@click="toggleMapType('MAP_SATELLITE')"
				:class="getButtonClass('MAP_SATELLITE')"
			>
				AERIAL
			</div>
			<div
				class="button rounded-none md:rounded-full cursor-pointer md:h-16 md:w-16 flex items-center justify-center text-md p-3 leading-none font-semibold text-center border-solid border border-gray-900 bg-white text-gray-900"
				@click="toggleMapType('MAP_STREET_VIEW')"
			>
				STREET<br />VIEW
			</div>
		</div>
	</transition>
</template>

<script>
	import eventBus from "../utils/event-bus";

	export default {
		name: "MapButtons",
		data: function() {
			return {
				activeButton: "MAP_SILVER",
				isVisible: false
			};
		},
		methods: {
			toggleMapType(mapType) {
				this.activeButton =
					mapType === "MAP_STREET_VIEW" ? this.activeButton : mapType;
				eventBus.$emit("MAP_TYPE_CHANGED", mapType);
			},
			getButtonClass(param) {
				return this.activeButton === param
					? "border bg-primary text-white"
					: "border border-gray-900 bg-white text-gray-900";
			}
		},
		mounted() {
			let app = this;
			eventBus.$on("POIS_LOADED", function() {
				app.isVisible = true;
			});
		}
	};
</script>
<style scoped>
	.mapbuttons .button {
		font-family: Rift-Regular;
	}
</style>
