<template>
	<div id="view" class="h-screen" style=""></div>
</template>

<script>
	import globals from "../utils/globals";
	import gmapsInit from "../utils/gmaps";
	import eventBus from "../utils/event-bus";

	export default {
		name: "MapView",
		props: ["poiCategories"],
		data: function() {
			return {
				map: null,
				panorama: null,
				selectedPOI: { lat: 37.3326248, lng: -121.9068344 },
				placeIds: [],
				index: 0,
				markers: [],
				places: [],
			};
		},
		methods: {
			handleMapTypeChange(payload) {
				switch (payload) {
					case "MAP_SILVER":
						this.setStreetView(false);
						this.toggleMarkers(true);
						this.map.setMapTypeId("roadmap");
						this.map.setTilt(0);
						this.map.setZoom(16);
						break;
					case "MAP_SATELLITE":
						this.setStreetView(false);
						this.toggleMarkers(true);
						this.map.setMapTypeId("satellite");
						this.map.setTilt(45);
						this.map.setZoom(18);
						break;
					case "MAP_STREET_VIEW":
						this.toggleMarkers(false);
						this.setStreetView(true);
						break;
					default:
						break;
				}
			},
			getPlaceIds() {
				let app = this;
				let count = 0;
				this.poiCategories.forEach(function(category) {
					category.pois.forEach(function(poi) {
						app.placeIds[count] = poi.placeId;
						count++;
					});
				});
				this.initMap();
			},
			getPoisDatailsByPlaceId(placeId) {
				let poiDetail = null;
				this.poiCategories.forEach(function(category) {
					category.pois.forEach(function(poi) {
						if (poi.placeId === placeId) {
							poiDetail = poi;
						}
					});
				});
				return poiDetail;
			},
			setPanoramaForPoi(poi) {
				this.selectedPOI.lat = poi.pano.lat; //marker.getPosition().lat();
				this.selectedPOI.lng = poi.pano.lng; //marker.getPosition().lng();
				this.panorama = this.map.getStreetView();
				this.panorama.setPosition(this.selectedPOI);
				this.panorama.setPov(
					/** @type {google.maps.StreetViewPov} */ ({
						heading: poi.pano.heading,
						pitch: poi.pano.pitch,
					})
				);
			},
			setMarkerHighlighted(marker, placeId) {
				this.markers.forEach(function(m) {
					m.setIcon(require("../assets/imgs/icons/map-pin-normal.png"));
					let label = m.getLabel();
					label.color = "black";
				});
				marker.setIcon(require("../assets/imgs/icons/map-pin-highlighted.png"));
				let label = marker.getLabel();
				label.color = "white";

				this.setPanoramaForPoi(this.getPoisDatailsByPlaceId(placeId));
			},
			getMarkerVisible(index) {
				let isVisible = false;
				this.poiCategories.forEach(function(category) {
					category.pois.forEach(function(poi) {
						if (poi.index === index) {
							isVisible = poi.isVisible;
						}
					});
				});
				return isVisible;
			},
			toggleMarkerVisibility(categoryId) {
				let app = this;
				let poiIndexes = [];
				let visibility = undefined;
				this.poiCategories.forEach(function(category) {
					if (category.id === categoryId) {
						visibility = category.isOpen;
						category.pois.forEach(function(poi) {
							poiIndexes.push(poi.index);
						});
					}
				});

				poiIndexes.forEach(function(index) {
					app.markers[index - 1].setVisible(visibility);
				});
			},
			toggleMarkers(bool) {
				var app = this;
				if (!bool) {
					this.markers.forEach((marker) => {
						marker.setVisible(bool);
					});
				} else {
					this.poiCategories.forEach(function(category) {
						if (category.isOpen) {
							category.pois.forEach(function(poi, index) {
								app.markers[index].setVisible(true);
							});
						}
					});
				}
			},
			async initMap() {
				let zoom = 0;
				if ( ( window.innerWidth <= 800 ) ) {
					zoom = 14;
				} else {
					zoom = 16;
				}
				try {
					
					const vespaio = { lat: 37.332493, lng: -121.9041784 };
					const google = await gmapsInit();
					this.map = new google.maps.Map(this.$el, {
						center: vespaio,
						zoom: zoom,
						styles: globals.MAP_SILVER,
						disableDefaultUI: true,
						zoomControl: true,
					});
					/* eslint-disable */
					var marker = new google.maps.Marker({
						map: this.map,
						position: vespaio,
						icon: require("../assets/imgs/icons/Vespaio-Map-icon-White.png"),
						animation: google.maps.Drop,
					});
					/* eslint-enable */

					this.getPoisList();
				} catch (error) {
					console.error(error);
				}
			},
			async getPoisList() {
				let app = this;
				/* eslint-disable */
				const google = await gmapsInit();
				var service = new google.maps.places.PlacesService(this.map);
				service.getDetails(
					{
						placeId: this.placeIds[this.index],
					},
					function(place, status) {
						//console.log(place);
						if (status === google.maps.places.PlacesServiceStatus.OK) {
							let placeIdIndex = app.placeIds.indexOf(place.place_id) + 1;

							var marker = new google.maps.Marker({
								map: app.map,
								position: place.geometry.location,
								visible: app.getMarkerVisible(placeIdIndex),
								icon: require("../assets/imgs/icons/map-pin-normal.png"),
								label: {
									text: placeIdIndex + "",
									color: "black",
								},

								animation: google.maps.Bounce,
							});

							eventBus.$emit("POI_LOADED", placeIdIndex);

							app.markers[placeIdIndex - 1] = marker;
							app.places[placeIdIndex - 1] = place;
							
							// google.maps.event.addListener(marker, "click", function() {
							// 		window.open(place.url, "_blank");
							// });

							google.maps.event.addListener(marker, "click", function() {
								if( ( window.innerWidth >= 800 ) ){
									window.open(place.url, "_blank");
								} else {
									app.setMarkerHighlighted(marker, place.place_id);
									app.selectedPOI.lat = marker.getPosition().lat();
									app.selectedPOI.lng = marker.getPosition().lng();
									eventBus.$emit(
										"MAP:POI_MOUSE_OVER",
										app.getPoisDatailsByPlaceId(place.place_id)
									);
								}
							});

							

							google.maps.event.addListener(marker, "mouseover", function() {
								app.setMarkerHighlighted(marker, place.place_id);
								app.selectedPOI.lat = marker.getPosition().lat();
								app.selectedPOI.lng = marker.getPosition().lng();
								eventBus.$emit(
									"MAP:POI_MOUSE_OVER",
									app.getPoisDatailsByPlaceId(place.place_id)
								);
							});

							app.index += 1;
							if (app.index === 1)
								new google.maps.event.trigger(marker, "mouseover");
							if (app.index < app.placeIds.length) {
								setTimeout(function() {
									app.getPoisList();
								}, 500);
							} else {
								eventBus.$emit("POIS_LOADED");
								return;
							}
						}
					}
				);
				/* eslint-enable */
			},
			setStreetView(bool) {
				this.panorama.setVisible(bool);
				this.toggleMarkerVisibility(!bool);
			},
		},
		mounted() {
			this.getPlaceIds();
		},
		created() {
			let app = this;
			eventBus.$on("MAP_TYPE_CHANGED", this.handleMapTypeChange);
			eventBus.$on("SIDEBAR:POI_MOUSE_OVER", function(poi) {
				app.setMarkerHighlighted(app.markers[poi.index - 1], poi.placeId);
			});
			eventBus.$on("SIDEBAR:POI_MOUSE_CLICK", function(index) {
				window.open(app.places[index - 1].url, "_blank");
			});
			eventBus.$on("LEARN_MORE", function(index) {
				window.open(app.places[index - 1].url, "_blank");
			});
			eventBus.$on("CATEGORY_TOGGLED", this.toggleMarkerVisibility);
		},
	};
</script>

<style lang="css" scoped>
	#view {
		background: white no-repeat;
		background-size: 100%;
	}
</style>
