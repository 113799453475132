<template>
	<div id="app" class="relative">
		<MapView :poiCategories="poiCategories"></MapView>
		<Sidebar id="sidebar" :poiCategories="poiCategories"></Sidebar>
		<POIDetails id="poiDetails"></POIDetails>
		<MapButtons id="mapButtons"></MapButtons>
	</div>
</template>

<script>
	import Vue from "vue";
	import globals from "./utils/globals";
	import Sidebar from "./components/Sidebar.vue";
	import MapButtons from "./components/MapButtons.vue";
	import POIDetails from "./components/POIDetails.vue";
	import MapView from "./components/MapView.vue";
	export default {
		name: "App",
		components: {
			MapView,
			Sidebar,
			MapButtons,
			POIDetails,
		},
		data() {
			return {
				poiCategories: [],
			};
		},
		methods: {},
		created() {
			this.poiCategories = globals.poiCategories;
			let count = 0;
			this.poiCategories.forEach(function(category) {
				category.pois.forEach(function(poi) {
					count += 1;
					poi.index = count;
					Vue.set(poi, "isDisabled", true);
					Vue.set(poi, "isSelected", false);
					Vue.set(poi, "isVisible", category.isOpen);
				});
			});
		},
	};
</script>

<style>
	*::-webkit-scrollbar {width: 4px;}
	*::-webkit-scrollbar-track {border-radius: 10px;background-color: #ffffff;}
	*::-webkit-scrollbar-thumb{border-radius: 10px;background-color: #bbbbbb;}
	html,
	body {
		margin: 0;
		padding: 0;
	}

	#app {
		width: 100vw;
		height: 100vh;
		font-family: "Open Sans", sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	#sidebar {
		top: 20px;
	}

	#sidebar.md\:w-1\/6 {
		width: 17.888889%;
	}

	#mapButtons {
		top: 18px;
		right: 0px;
		right: 20px;
	}
	#poiDetails {
		top: 21px;
		left: 272px;
	}
	@media only screen and (min-width:1700px){
		#poiDetails {
			top: 19px;
			left: 350px;
		}
	}
	@media only screen and (min-width: 1920px){
		#poiDetails {
			top: 19px;
			left: 350px;
		}
	}
	@media only screen and (min-width: 2304px){
		#poiDetails {
			top: 19px;
			left: 395px;
		}
	}
	@media only screen and (min-width: 3000px){
		#poiDetails {
			top: 19px;
			left: 528px;
		}
	}
	@media only screen and (min-width: 4000px){
		#poiDetails {
			top: 19px;
			left: 790px;
		}
	}
	@media only screen and (max-width:767px){
		#poiDetails {
			top: unset;
			left: 0;
			bottom: 0;
		}
		#mapButtons {
			top: 5px;
			right: 37px;
			height: 125px;
		}
	}

	@media only screen and (max-width:767px){
		#sidebar.md\:w-1\/6 {
			width: 66.888889%;
		}
		
		.mapbuttons .button {
			padding: 6px 0;
			font-size: 15px;
		}
	}
	
</style>
