import Vue from "vue";
import App from "./App.vue";
import "animate.css/animate.min.css";
import "@/assets/css/tailwind.css";
import "./assets/css/fontawesome/all.min.css";
import "@/assets/css/style.css";

window.eventBus = new Vue();

Vue.config.productionTip = false;

new Vue({
	render: (h) => h(App),
}).$mount("#app");
